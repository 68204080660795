.marksheet-table {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 12px;
  text-align: center;
}

.table-actions{
  display: inline-flex ;
}

.anticon-delete{
  padding-top: 5px;
}

.anticon-edit{
  padding-top: 5px;
}

.name {
  display: flex;
  flex-direction: row;
  gap: 1;
}
.add {
  display: flex;
  align-items: center;
}
.title-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


