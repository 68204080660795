@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';

@font-face {
    font-family: "sf-ui-display-bold";
    src: url("/public/assets/fonts/sf-ui-display-bold.woff");
}

@font-face {
    font-family: "sf-ui-display-medium";
    src: url("/public/assets/fonts/sf-ui-display-medium.woff");
}

@font-face {
    font-family: "sf-ui-display-heavy";
    src: url("/public/assets/fonts/sf-ui-display-heavy.woff");
}
