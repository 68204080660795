* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");

@font-face {
  font-family: "sf-ui-display-medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("https://res.cloudinary.com/ddizpfhtk/raw/upload/v1667998612/iide/marksheet/fonts/sf-ui-display-medium_f1auaj.woff")
      format("woff");
}
@font-face {
  font-family: "sf-ui-display-bold";
  font-style: regular;
  font-weight: normal;
  src: local("SF UI Display Bold"),
    url("https://res.cloudinary.com/ddizpfhtk/raw/upload/v1667998612/iide/marksheet/fonts/sf-ui-display-bold_v40316.woff")
      format("woff");
}
@font-face {
  font-family: "old-london";
  font-style: regural;
  font-weight: normal;
  src: local("Old London"),
    url("https://res.cloudinary.com/ddizpfhtk/raw/upload/v1673527951/iide/marksheet/fonts/OldLondon_bmrzvl.woff")
      format("woff");
}

.app {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.detail {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 30px 0;
}

.detail .big-img {
  max-width: 500px;
  min-width: 290px;
  overflow: hidden;
  margin: 25px;
  box-shadow: 0 0 5px #ccc;
}

.big-img img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  display: block;
  object-fit: cover;
}

.detail .box {
  max-width: 500px;
  min-width: 290px;
  margin-top: 100px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}

.box .row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
}

.box .row span {
  font-size: 17px;
  font-weight: 600;
  /* margin-bottom: 5px; */
}

.name {
  font-family: sf-ui-display-medium;
}

/* .box .row h2 {
  font-size: 35px;
  font-weight: 700;
  padding-top: 25px;
  line-height: 1.2;
} */

.social-link {
  display: flex;
  justify-content: space-between;
}

.box .social-link button {
  width: 242px;
  border: 1.5px solid #039be5;
  padding: 8px;
  --tw-border-opacity: 1;
  border-radius: 5px;
  font-size: medium;
  font-weight: bold;
  color: #039be5;
  font-family: "sf-ui-display-medium";
}

.social-link .linked-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.iide-social-section {
  margin-top: 15px;
  padding: 15px;
  display: flex;
  gap: 3rem;
  background-color: #f7f9ff;
  border-radius: 15px;
}

.box .social-link button:nth-child(1) {
  background-color: #039be5;
  color: white;
  border: none;
}
.box .social-link button:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .box .social-link button:nth-child(1) svg {
  margin: 0;
  margin-left: 4px;
  margin-bottom: 3px;
  margin-left: 3px;
  color: #039be5;
} */

/* .box .social-link button:nth-child(1) svg:hover {
  margin: 0;
  margin-left: 4px;
  margin-bottom: 3px;
  margin-left: 3px;
  color: white !important;
} */

.iide-social-section .iide-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px !important;
  height: 165px !important;
  box-shadow: 0 0 5px #ccc;
  border-radius: 15px;
}

.iide-social-link .title {
  font-size: large;
  font-weight: bold;
}

.iide-social-link button {
  width: 171px;
  margin-top: 10px;
  border-radius: 8px;
}

.iide-social-link .site-btn {
  background-color: #039be5;
  color: white;
  padding: 10px 0;
}

.lottie {
  width: 8%;
  height: 35px !important;
  padding-left: 5px;
  display: inline-block;
}

.your-linkedin {
  border: 2px solid white;
  border-radius: 5px;
  padding: 1.5px;
}

.cert {
  position: relative;
  font-family: "sf-ui-display-medium";
}

.name {
  /* display: flex;
  flex-direction: column; */
  margin: 15px 0;
}

.download-btn {
  transition: 0.1s all;
  margin-left: 5px;
  height: 41px;
}

.download-btn:hover {
  background-color: #039be5;
  color: white !important;
}

@media only screen and (max-width: 1000px) {
  .detail {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .detail .box {
    max-width: 500px;
    min-width: 290px;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 25px;
  }

  .social-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .box .social-link a > button {
    width: 100%;
  }
  .box .social-link button:nth-child(1) {
    border: none;
  }
  .box .social-link button:nth-child(2) {
    width: auto;
  }

  .lottie {
    width: 29px;
    height: 24px !important;
    display: inline-block;
  }

  .cert {
    font-family: "sf-ui-display-bold";
  }
  .download-btn {
    transition: 0.1s all;
    margin-left: 0px;
    height: 41px
  }
}
